import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Cart from "./pages/Cart";
import Dashboard from "./pages/dashboard";
import Billing from "./pages/billing";
import LandingPage from './pages/Landing';
import Agreement from './pages/Agreement';
import Success from './pages/success';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/agreement" element={<Agreement />} />
                <Route path={'/:id'} element={<App/>}>
                    <Route path={'/:id'} element={<Dashboard/>}/>
                    <Route path={'/:id/cart'} element={<Cart/>}/>
                    <Route path={'/:id/billing'} element={<Billing/>}/>
                    <Route path={'/:id/success'} element={<Success />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
