import { Mode, Task } from "@mui/icons-material";
import { access } from "fs";
import {create} from "zustand";
import {devtools} from "zustand/middleware";

export interface good {
    id: number,
    type_id ?: number,
    image: string | null,
    name: string,
    name_kz: string,
    description: string,
    quantity: number,
    price: number,
    originalPrice?: number,
    maxQuantity?: number
}

interface orderGood {
    id: number,
    quantity: number
}

interface createOrder{
    account: number,
    goods: orderGood[]
}

interface createOrderCard{
    amount: number,
    account: number
}

export interface searchGood extends good {
    type_name: string
}

export interface ProductType {
    id: string,
    name: string,
    discount: {
        id: number,
        discount_amount: string
    } | null
}

interface IState {
    goods: good[],
    cart: {
        goods: good[],
        original_total: number,
        total: number,
        discount_total: number
    },
    fridgeId: string,
    goodQuantity: number,
    lang: 'Ru' | 'Kz',
    searchGoods: { goods: searchGood[], status: 'not-loading' | 'loading' | 'loaded' | 'error' },
    productId: string,
    status: string,
    setLang: (lang:'Ru'|'Kz') => void,
    productTypes: ProductType[],
    activeProductType: string,
    increment: (id: number) => void,
    decrement: (id: number) => void,
    incrementInCart: (id: number) => void,
    decrementInCart: (id: number) => void,
    getCartGoods: () => void,
    getGoodQuantityInCart: () => void,
    fetchProductTypes: (fridgeId: string) => void,
    setActiveProductType: (id: string) => void,
    getGoods: (typeId:string, fridgeId: string) => void,
    fetchSearch: ({fridgeId, searching}: any) => void,
    fetchSearchLoading: () => void,
    setProductId: (id: string) => void,
    setSearchDataNull: () => void,
    setFridgeId: (fridgeId: string) => void,
    postOrder: (createOrder: createOrder) => void,
    postOrderCard: (createOrderCard: createOrderCard) => void,
    openDoor: (fridgeId: string | undefined, goods: { id: number; quantity: number }[]) => void,
    clearCart: () => void,
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const useStore = create<IState>()(
    devtools(
        (set) => ({
            goods: [],
            cart: {
                goods: [],
                original_total: 0,
                total: 0,
                discount_total: 0
            },
            goodQuantity: 0,
            fridgeId: '',
            productTypes: [],
            productId: '',
            status: '',
            activeProductType: "",
            lang: 'Ru',
            searchGoods: {
                goods: [],
                status: 'not-loading'
            },
            clearCart: () => set((state) => ({
                cart: {
                  goods: [],
                  original_total: 0,
                  total: 0,
                  discount_total: 0,
                },
                goodQuantity: 0,
                goods: state.goods.map(product => ({ ...product, quantity: 0 })),
              })),
            increment: (id: number) => set((state) => ({
                goods: state.goods.map(good => {
                    if (good.id === id) {
                        // Prevent increment if maxQuantity is defined and already reached
                        if (good.maxQuantity !== undefined && good.quantity >= good.maxQuantity) {
                            return good;
                        }
                        return { ...good, quantity: good.quantity + 1 };
                    }
                    return good;
                })
            }), false, 'increment'),
            decrement: (id: number) => set((state) => {
                const updatedGoods = state.goods.map(good =>
                    good.id === id ? { ...good, quantity: good.quantity > 0 ? good.quantity - 1 : 0 } : good
                );
                const cartGoods = state.cart.goods.filter(cartGood =>
                    cartGood.id !== id || (cartGood.id === id && cartGood.quantity > 1)
                );
                const total = cartGoods.reduce((sum, good) => sum + ((good.originalPrice ? good.originalPrice: good.price)  * good.quantity), 0);
                const discount_total = cartGoods.reduce((sum, good) => sum + (good.originalPrice ?((good.price - good.originalPrice)  * good.quantity) : 0), 0);
                const original_total = cartGoods.reduce((sum, good) => sum + ((good.price) * good.quantity), 0);
                return { goods: updatedGoods, cart: { goods: cartGoods, total: total, discount_total:discount_total, original_total: original_total } };
            }, false, 'decrement'),
            incrementInCart: (id: number) => set((state) => {
                const updatedCartGoods = state.cart.goods.map((good) =>
                    good.id === id 
                      ? (good.maxQuantity !== undefined && good.quantity >= good.maxQuantity 
                           ? good 
                           : { ...good, quantity: good.quantity + 1 }) 
                      : good
                );
                // Recalculate totals...
                const total = updatedCartGoods.reduce((sum, good) => sum + ((good.originalPrice ? good.originalPrice : good.price) * good.quantity), 0);
                const discount_total = updatedCartGoods.reduce((sum, good) => sum + (good.originalPrice ? ((good.price - good.originalPrice) * good.quantity) : 0), 0);
                const original_total = updatedCartGoods.reduce((sum, good) => sum + ((good.price) * good.quantity), 0);
                return { cart: { ...state.cart, goods: updatedCartGoods, total, discount_total, original_total }, goods: [] };
            }, false, 'incrementInCart'),
            decrementInCart: (id: number) => set((state) => {
                const updatedCartGoods = state.cart.goods
                    .map((good) =>
                        good.id === id ? { ...good, quantity: Math.max(0, good.quantity - 1) } : good
                    )
                    .filter(good => good.quantity > 0);
                const total = updatedCartGoods.reduce((sum, good) => sum + ((good.originalPrice ? good.originalPrice: good.price) * good.quantity), 0);
                const discount_total = updatedCartGoods.reduce((sum, good) => sum + (good.originalPrice ?((good.price - good.originalPrice)  * good.quantity) : 0), 0);
                const original_total = updatedCartGoods.reduce((sum, good) => sum + ((good.price) * good.quantity), 0);
                return { cart: { ...state.cart, goods: updatedCartGoods, total, discount_total, original_total }, goods: [] };
            }, false, 'decrementInCart'),
            getCartGoods: () => set((state) => {
                const cartGoods = state.goods.filter(good => good.quantity >= 1);
                const totalGoods = state.cart.goods.filter(good => !cartGoods.some(element => element.id === good.id));
                const fullGood = cartGoods.concat(totalGoods)
                const total = fullGood.reduce((sum, good) => sum + ((good.originalPrice ? good.originalPrice: good.price) * good.quantity), 0);
                const discount_total = fullGood.reduce((sum, good) => sum + (good.originalPrice ?((good.price - good.originalPrice)  * good.quantity) : 0), 0);
                const original_total = fullGood.reduce((sum, good) => sum + ((good.price) * good.quantity), 0);
                return { cart: { goods: fullGood, total: total, discount_total: discount_total, original_total } };
            }, false, 'getCartGoods'),
            getGoodQuantityInCart: () => set((state) => {
                const goods = state.cart.goods.filter((good: good) => good.quantity >= 1)
                return { goodQuantity: goods.length };
            }, false, 'getGoodQuantityInCart'),
            fetchProductTypes: async (fridgeId: string) => {
                const response = await fetch(`https://cabinet.vbox.kz/api/gettypes/?account=${fridgeId}`);
                const data = await response.json();
                const productTypes = data.types;
                if (productTypes && Array.isArray(productTypes)) {
                    const sortedProductTypes = productTypes.sort((a, b) => a.id - b.id);
            
                    set({
                        productTypes: sortedProductTypes,
                        activeProductType: sortedProductTypes.length > 0 ? sortedProductTypes[0].id : ""
                    });
                }
            },
            setActiveProductType: (id: string) => set({ activeProductType: id }, false, 'setActiveProductType'),
            getGoods: async (typesId: string, fridgeId: string) => {
                const response = await fetch(`https://cabinet.vbox.kz/api/getproducts/?account=${fridgeId}&typesId=${typesId}`);
                const data = await response.json();
                if (data.comment === 'OK') {
                    let fetchedGoods = data.products.map((item: any) => ({
                        id: Number(item.id),
                        type_id: Number(typesId),
                        image: item.image_url,
                        name: item.name[0],
                        name_kz: item.name[1],
                        description: '',
                        quantity: 0,
                        price: Number(item.sum),
                        originalPrice: Number(item.sum_with_discount),
                        maxQuantity: Number(item.quantity)
                    }));

                    set((state) => {
                        const existingCartGoods = state.cart.goods.filter(good => good.type_id === Number(typesId));
                        const updatedGoods = fetchedGoods.map((good: good) => {
                            const cartGood = existingCartGoods.find(cg => cg.id === good.id);
                            if (cartGood) {
                                return { ...good, quantity: cartGood.quantity };
                            }
                            return good;
                        });

                        return { goods: updatedGoods };
                    });
                } else {
                    set({ goods: [] });
                }
            },
            setLang: (lang) => set({ lang: lang }, false, 'setLang'),
            fetchSearch: async ({fridgeId, searching}) =>  {
                const response = await fetch(`https://cabinet.vbox.kz/api/search/?txn_id=1&account=${fridgeId}&search_term=`+encodeURIComponent(searching));
                const data = await response.json();
                if (data.comment === 'OK') {
                    let fetchedGoods = data.products.map((item: any) => ({
                        id: Number(item.id),
                        type_id: Number(item.type_id),
                        image: item.image_url,
                        name: item.name[0],
                        name_kz: item.name[1],
                        description: '',
                        quantity: 0,
                        type_name: item.type_name,
                        price: Number(item.price)
                    }));

                    set((state) => {
                        return { searchGoods: {goods: fetchedGoods, status: 'loaded'} };
                    });
                } else {
                    set({ searchGoods: {goods: [], status: 'error'} });
                }
            },
            fetchSearchLoading: () => set(() => ({searchGoods: {goods: [], status: 'loading'}}), false, 'fetchSearchLoading'),
            setProductId: (id) => set(() => ({productId: id}), false, 'setProductId'),
            setSearchDataNull: () => set(() => ({searchGoods: {goods: [], status: 'not-loading'}}), false, 'setSearchDataNull'),
            setFridgeId: (fridgeId) => set(() => ({fridgeId: fridgeId}), false, 'setFridgeId'),
            postOrder: async (createOrder: createOrder) => {
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json' // Type of content being sent
                    },
                    body: JSON.stringify(createOrder) // Converting the data to a JSON string
                };
                const response = await fetch(`https://cabinet.vbox.kz/api/createorder/`, options);
                const data = await response.json();
                const order_id = data.order_id;
                const kaspiPay = `https://kaspi.kz/pay/VBox?service_id=10401&15096=${order_id}`
                window.location.href = kaspiPay;
            },
            postOrderCard: async (createOrderCard: createOrderCard) => {
                var ipAddress;
                var name;
                if(createOrderCard.account === 17){
                    ipAddress = '192.168.43.227'
                    name = 'iqcoworking'
                }
                else if(createOrderCard.account === 420){
                    ipAddress = '192.168.43.100'
                    name = 'sergek'
                }
                else if(createOrderCard.account === 7){
                    ipAddress = '172.16.0.103'
                    name = 'pyramida'
                }
                else{
                    ipAddress = '192.168.100.183'
                    name = 'yesvbox2'
                }
                const tokenResponse = await fetch(`https://cabinet.vbox.kz/api/getaccesstoken?account=${createOrderCard.account}`, {method: 'GET', headers: { 'Authorization': `Token ${process.env.REACT_APP_TOKEN}`}});
                const tokenData = await tokenResponse.json()
                var accessToken;

                const current = new Date();
                const expiration = new Date(tokenData.expiration_date.replace(" ", "T"));
                const adjustedCurrent = new Date(current.getTime() + 12 * 60 * 60 * 1000);

                if(expiration.getTime() < adjustedCurrent.getTime()){
                    const revokeResponse = await fetch(`https://${ipAddress}:8080/v2/revoke?name=${name}&refreshToken=${tokenData.refresh_token}`);
                    const revokeData = await revokeResponse.json();
                    const payload = {
                        data: revokeData.data,
                        account: createOrderCard.account
                    }

                    await fetch(`https://cabinet.vbox.kz/api/createaccesstoken/`, {method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${process.env.REACT_APP_TOKEN}`}, body: JSON.stringify(payload)});

                    accessToken = revokeData.data.accessToken;
                }
                else{
                    accessToken = tokenData.access_token;
                }             
   

                const options = {
                    method: 'GET',
                    headers: {
                        'accesstoken': accessToken
                    }
                };

                const payResponse = await fetch(`https://${ipAddress}:8080/v2/payment?amount=${createOrderCard.amount}`, options);
                const payData = await payResponse.json();
                const processId = payData.data.processId;

                while(true){
                    var statusResponse = await fetch(`https://${ipAddress}:8080/v2/status?processId=${processId}`, options);
                    const statusData = await statusResponse.json();
                    await sleep(1000)
                    if(statusData.data.status === 'success'){
                        set({status: statusData.data.status})
                        console.log(statusData.data.status)
                        break;
                    }
                    if(statusData.data.status === 'unknown'){
                        set({status: statusData.data.status})
                        break;
                    }
                    if(statusData.data.status === 'fail'){
                        set({status: statusData.data.status})
                        break;
                    }
                }
            },
            openDoor: async (fridgeId, goods) => {
                const payload = {
                    goods: goods,
                    account: fridgeId
                }
              
                const response = await fetch('https://cabinet.vbox.kz/api/opendoor/', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${process.env.REACT_APP_TOKEN}` },
                  body: JSON.stringify(payload)
                });
                const data = await response.json();
                console.log(data);
              },
        })
    )
)