import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  List, 
  ListItem, 
  ListItemAvatar, 
  Avatar, 
  ListItemText, 
  Button 
} from '@mui/material';
import { useStore } from "../store/useStore";

interface LocationState {
  id: string; // The dynamic id to be used in the redirect URL.
  items: {
    id: number;
    name: string;
    quantity: number;
    image: string | null;
    // add other fields if necessary
  }[];
}

const Success = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const clearCart = useStore(state => state.clearCart);

  // Destructure state with safe defaults in case state is undefined.
  const { id, items } = (state as LocationState) || { id: '', items: [] };

  const handleBackToMain = () =>{
    clearCart()
    navigate(`/${id}`)
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Оплата прошла успешно!
      </Typography>
      <Typography variant="h6" gutterBottom>
        Вы приобрели:
      </Typography>
      {items.length ? (
        <List>
          {items.map(item => (
            <ListItem key={item.id}>
              <ListItemAvatar>
                {item.image ? (
                  <Avatar src={item.image} alt={item.name} />
                ) : (
                  <Avatar>{item.name.charAt(0)}</Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={item.name}
                secondary={`Количество: ${item.quantity}`}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>Нет товаров для отображения.</Typography>
      )}
      <Button
        onClick={handleBackToMain}
        sx={{
          width: '100%',
          height: '72px',
          borderRadius: '15px',
          backgroundColor: '#000',
          color: '#FFFFFF',
          padding: '12px 14px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
          transition: 'transform 0.4s ease-in-out',
          mt: 2,
          // Mimic the scale animation on hover as seen in the payment buttons:
          '&:hover': {
            backgroundColor: '#000',
            transform: 'scale(1.075)',
          },
        }}
      >
        Вернуться на главную
      </Button>
    </Container>
  );
};

export default Success;
