import React from 'react';
import { Box } from "@mui/material";
import { good, useStore } from "../store/useStore";
import {useLocation} from "react-router-dom";

const Card = ({ id, name, name_kz, description, price, quantity, image, originalPrice, maxQuantity }: good) => {
    const location = useLocation()
    const increment = useStore(state => state.increment);
    const decrement = useStore(state => state.decrement);
    const incrementInCart = useStore(state => state.incrementInCart);
    const decrementInCart = useStore(state => state.decrementInCart);
    const getCartGoods = useStore(state => state.getCartGoods);
    const lang = useStore(state => state.lang);
    const getGoodQuantityInCart = useStore(state => state.getGoodQuantityInCart);
    const productId = useStore(state => state.productId);
    const fridgeId = useStore(state => state.fridgeId);

    const handleInc = () => {
        if(location.pathname === `/${fridgeId}/cart`){
            incrementInCart(id);
        } else {
            increment(id);
            getCartGoods();
            getGoodQuantityInCart();
        }
    }

    const handleDec = () => {
        if(location.pathname === `/${fridgeId}/cart`){
            decrementInCart(id);
        } else {
            decrement(id);
            getCartGoods();
            getGoodQuantityInCart();
        }
    }

    return (
        <Box id={`${id}`} sx={{
            bgcolor: '#FFFFFF',
            boxShadow: '0px 0px 60px #00000050',
            p: '10px',
            borderRadius: '10px',
            display: 'flex',
            gap: 1,
        }}
             className={productId === `${id}` ? 'highlight' : ''}
        >
            {/* Image content */}
            <Box sx={{
                height: '80px',
                width: '80px',
                bgcolor: "#ADADAD",
                borderRadius: '10px'
            }}>
                {image ? (
                    <img src={image} style={{ height: '80px', width: '80px', borderRadius: '10px' }} alt="" />
                ) : null}
            </Box>
            {/* Product information */}
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                {/* Title of the product */}
                <h5 style={{
                    fontWeight: '700',
                    fontSize: '18px',
                    color: '#000000',
                    margin: 0,
                    textAlign: 'left'
                }}>{lang === 'Ru' ? name : name_kz}</h5>
                {/* Description of the product */}
                <p style={{
                    fontWeight: '400',
                    fontSize: '12px',
                    color: '#ADADAD',
                    margin: 0,
                    textAlign: 'left'
                }}>{description}</p>
            </Box>
            {/* Price and button to access add the product to the cart */}
            <Box sx={{
                height: '80px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '25%',
                position: 'relative'
            }}>
                {/*sale product price*/}
                {originalPrice ? (
                    <Box sx={{position: 'relative', width:'fit-content', marginLeft: 'auto'}}>
                        <p style={{fontSize: 13, fontWeight: '700', color: '#00000040', margin: '0', display: 'inline', }}>{price} тг</p>
                        <div style={{width: '100%', position: 'absolute', top: '60%', transform: 'translateY(-50%)', height: '1px', background: '#F16666'}}></div>
                    </Box>
                ) : null}
                {/* Price of the product */}
                <p style={{
                    fontWeight: '700',
                    fontSize: '18px',
                    color: originalPrice ? '#E8000095' : '#00000080',
                    textAlign: 'end',
                    margin: 0,
                }}>{originalPrice ? originalPrice : price} тг</p>
                {/* Wrapper of the buttons */}
                <Box sx={{
                    bgcolor: "#F5F5F5",
                    p: "5px",
                    borderRadius: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    justifyContent: 'space-between'
                }}>
                    {/* Drop the number of the product */}
                    <button
                        onClick={() => {
                            handleDec();
                        }}
                        style={{
                            border: "none",
                            background: 'transparent',
                            padding: 0,
                            height: "16px",
                        }}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M1.75 8C1.75 7.80109 1.82902 7.61032 1.96967 7.46967C2.11032 7.32902 2.30109 7.25 2.5 7.25H13.5C13.6989 7.25 13.8897 7.32902 14.0303 7.46967C14.171 7.61032 14.25 7.80109 14.25 8C14.25 8.19891 14.171 8.38968 14.0303 8.53033C13.8897 8.67098 13.6989 8.75 13.5 8.75H2.5C2.30109 8.75 2.11032 8.67098 1.96967 8.53033C1.82902 8.38968 1.75 8.19891 1.75 8Z"
                                  fill="#B1B1B1" />
                        </svg>
                    </button>
                    {/* Quantity of the product */}
                    <span style={{ fontWeight: '600' }}>{quantity}</span>
                    {/* Increase the number of the product */}
                    <button
                        onClick={() => {
                            handleInc();
                        }}
                        disabled={maxQuantity !== undefined ? quantity >= maxQuantity : false}
                        style={{
                            border: "none",
                            background: 'transparent',
                            padding: 0,
                            height: "16px"
                        }}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.9999 8.66536H8.66658V11.9987C8.66658 12.1755 8.59635 12.3451 8.47132 12.4701C8.3463 12.5951 8.17673 12.6654 7.99992 12.6654C7.82311 12.6654 7.65354 12.5951 7.52851 12.4701C7.40349 12.3451 7.33325 12.1755 7.33325 11.9987V8.66536H3.99992C3.82311 8.66536 3.65354 8.59513 3.52851 8.4701C3.40349 8.34508 3.33325 8.17551 3.33325 7.9987C3.33325 7.82189 3.40349 7.65232 3.52851 7.52729C3.65354 7.40227 3.82311 7.33203 3.99992 7.33203H7.33325V3.9987C7.33325 3.82189 7.40349 3.65232 7.52851 3.52729C7.65354 3.40227 7.82311 3.33203 7.99992 3.33203C8.17673 3.33203 8.3463 3.40227 8.47132 3.52729C8.59635 3.65232 8.66658 3.82189 8.66658 3.9987V7.33203H11.9999C12.1767 7.33203 12.3463 7.40227 12.4713 7.52729C12.5963 7.65232 12.6666 7.82189 12.6666 7.9987C12.6666 8.17551 12.5963 8.34508 12.4713 8.4701C12.3463 8.59513 12.1767 8.66536 11.9999 8.66536Z"
                                fill="#FFB839" />
                        </svg>
                    </button>
                </Box>
            </Box>
        </Box>
    );
};

export default Card;
