import React, { useState } from 'react';
import {
  AppBar,
  Box,
  FormControl,
  MenuItem,
  Select,
  Toolbar,
  IconButton,
  Drawer,
  Typography,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../store/useStore";
import BackButton from './BackButton';  // Import the BackButton

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();  
  const fridgeId = useStore(state => state.fridgeId);
  const navigate = useNavigate();
  const clearCart = useStore(state => state.clearCart);

  const lang = useStore(state => state.lang);
  const setLang = useStore(state => state.setLang);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleChange = (event: any) => {
    setLang(event.target.value);
  };

  const handleLogo = () => {
    clearCart();
  };

  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent" style={{ boxShadow: 'none', marginBottom: '32px' }}>
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          {/* VBox Logo */}
          <Link to={`/${fridgeId}`} onClick={handleLogo} style={{
            background: '#FFB839',
            padding: '4px 16px',
            borderRadius: '100px',
            fontWeight: '700',
            textDecoration: 'none',
            fontSize: 24,
            color: '#FFFFFF'
          }}>VBox</Link>

          {/* Right Side: Language Selector + Menu Icon */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{ m: 1, minWidth: 50 }}>
              <Select
                value={lang}
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                  bgcolor: '#F4F4F4',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  '& .MuiOutlinedInput-input': { p: 1.5 },
                  borderRadius: '40px',
                  fontWeight: '700',
                  '& .MuiOutlinedInput-input:focused': {
                    borderColor: 'transparent'
                  }
                }}
              >
                <MenuItem value={'Ru'}>Ru</MenuItem>
                <MenuItem value={'Kz'}>Kz</MenuItem>
              </Select>
            </FormControl>

            <IconButton
              onClick={toggleDrawer(true)}
              sx={{ color: '#000', ml: 1 }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      {children}

      {/* Render BackButton on inner pages */}
      <BackButton />

      {/* Drawer (Side Menu) */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250, p: 2 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>О компании</Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>ТОО "Sheksiz Orта", БИН: 200940028737</Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>Адрес: г.Астана, район Есиль, улица Сауран, 9</Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>Телефон: +7 775 135 4414</Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            <Link to="/agreement" style={{ color: '#FFB839', textDecoration: 'none', fontWeight: 'bold' }}>
              Наше соглашение (Agreement)
            </Link>
          </Typography>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Layout;
