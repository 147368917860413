import React from 'react';
import { Box } from '@mui/material';

const Agreement: React.FC = () => {
  return (
    <Box sx={{ width: '100%', height: '100vh' }}>
      <iframe 
        title="Agreement PDF" 
        src="/agreement.pdf" 
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </Box>
  );
};

export default Agreement;