import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';

const LandingPage: React.FC = () => {
  return (
    <Box 
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: '#F9F9F9',
      }}
    >
      {/* Hero Section */}
      <Box
              component="img"
              src="/vbox.png"
              alt="VBox — Социальный стартап"
              sx={{
                maxWidth: "100%",
                height: "auto",      // let height adjust to keep aspect ratio
                display: "block",    // removes the inline gap under images
                margin: "0 auto",    // center if needed
              }}
      >
      </Box>

      {/* About Section */}
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>
          About VBox
        </Typography>
        <Typography variant="body1" paragraph>
          VBox is an innovative company specializing in smart vending and automated retail solutions. 
          Our mission is to revolutionize the way you distribute products and delight your customers 
          using modern technology and sleek interfaces. 
        </Typography>
        <Typography variant="body1" paragraph>
          We offer a user-friendly admin dashboard, real-time inventory management, and seamless payment 
          integrations, so you can focus on growing your business while we handle the rest.
        </Typography>
      </Container>

      {/* Footer Section */}
      <Box
  component="footer"
  sx={{
    mt: 'auto',
    py: 2,
    backgroundColor: '#333',
    color: '#FFF',
  }}
>
  <Container
    // Make the container a Flexbox row
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 3,           // spacing between items
      flexWrap: 'wrap'  // if the screen is too small, items wrap to the next line
    }}
  >
    <Typography variant="body2">
      ТОО "Sheksiz Orта", БИН: 200940028737
    </Typography>

    <Typography variant="body2">
      Адрес: г.Астана, район Есиль, улица Сауран, 9
    </Typography>

    <Typography variant="body2">
      Телефон: +7 775 135 4414
    </Typography>

    <Typography variant="body2">
      <a
        href="/agreement"
        style={{ color: '#FFB839', textDecoration: 'none', fontWeight: 'bold' }}
      >
        Наше соглашение (Agreement)
      </a>
    </Typography>
  </Container>
</Box>

    </Box>
  );
};

export default LandingPage;
