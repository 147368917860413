import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStore } from '../store/useStore';

const BackButton: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fridgeId = useStore(state => state.fridgeId);
  const clearCart = useStore(state => state.clearCart);

  // Determine the destination based on the current pathname:
  let destination: string | null = null;
  
  if (location.pathname.endsWith('/billing')) {
    destination = `/${fridgeId}/cart`;
  } else if (location.pathname.endsWith('/cart')) {
    destination = `/${fridgeId}`;
  } else if (location.pathname.endsWith('/success')) {
    // For example, after a successful payment, go back to the dashboard.
    destination = `/${fridgeId}`;
  }
  
  // If no valid destination or already on the main dashboard, do not render the button.
  if (!destination || location.pathname === `/${fridgeId}` || location.pathname.endsWith('/success')) {
    return null;
  }

  const handleBack = () => {
    if (location.pathname.endsWith('/cart')) {
      clearCart();
    }
    navigate(destination!);
  };

  return (
    <IconButton
      onClick={handleBack}
      style={{
        position: 'fixed',
        bottom: '40px',
        left: '7.5%', // Percentage-based positioning to align with the payment button's margin on mobile and other devices.
        zIndex: 1000,
        backgroundColor: '#000', // Changed from white to a contrasting color.
        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
      }}
    >
      <ArrowBackIcon style={{ color: '#fff' }} />
    </IconButton>
  );
};

export default BackButton;
